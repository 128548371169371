
import { defineComponent, ref, computed, PropType } from "vue";
import { QuestionCircleOutlined, SettingOutlined } from "@ant-design/icons-vue";
import { NewPrivatePersonContractStepsData } from "@/common/managementObjects";
import { Logger } from "@/common/logger";

export default defineComponent({
  name: "AddPrivatePersonContractsStep1",
  emits: ["update:modelValue"], // The component emits an event
  props: {
    modelValue: {
      type: Object as PropType<NewPrivatePersonContractStepsData>,
      required: true,
    },
  },
  components: {
    QuestionCircleOutlined,
    SettingOutlined
  },
  setup(props, { emit }) {
    const rules = {
      contractNumber: [
        {
          required: true,
          message: "Без номера лицевого счёта ничего добавить не получится",
          trigger: "change",
        },
      ],
      validationCode: [
        {
          required: true,
          message: "По коду подтверждения я пойму, что вы - настоящий",
          trigger: "change",
        },

        {
          len: 6,
          message: "Код, совершенно точно, состоит из 6 цифр",
          trigger: "blur",
        },
      ],
      emailReceipt: [

      ]
    };

    const formModel = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    return {
      formModel,
      addPrivateContractFormRef: ref(),
      rules,
    };
  },

  methods: {
    async validate(): Promise<boolean> {
      Logger.logInfo("Validate step 2 CALLED!");
      try {
        const res = await this.addPrivateContractFormRef.validate();
        Logger.logInfo("Validate result", res);
        return true;
      } catch (exc) {
        Logger.logError("Validate step 2 FAILED!", exc);
        return false;
      }
    },
  },
});
