
import { defineComponent, ref, reactive } from "vue";
import { QuestionCircleOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { NewPrivatePersonContractStepsData } from "@/common/managementObjects";
import { Logger } from "@/common/logger";
import ProxyFactory from "@/apiProxies/proxyFactory";
import { managementObjectsModule } from "@/store/modules/managementObjects";
import { authenticationModule } from "@/store/modules/authentication";
import AddPrivatePersonContractsStep1 from "@/components/AddPrivatePersonContractStep1.vue";
import AddPrivatePersonContractsStep2 from "@/components/AddPrivatePersonContractStep2.vue";
import AddPrivatePersonContractsStep3 from "@/components/AddPrivatePersonContractStep3.vue";
import { DialogResult } from "@/common/dialog";
import { useRouter } from "vue-router";
import { AppRouteNames } from "@/router/route-names";

export default defineComponent({
  name: "AddPrivatePersonContractDialog",
  components: {
    CloseOutlined,
    QuestionCircleOutlined,
    ExclamationCircleOutlined,

    AddPrivatePersonContractsStep1,
    AddPrivatePersonContractsStep2,
    AddPrivatePersonContractsStep3,
  },
  mounted() {
    this.currentStep = 0;
    managementObjectsModule.getPrivatePersonContractInitialData()
  },
  setup() {
    const steps = [
      { component: "AddPrivatePersonContractsStep1" },
      { component: "AddPrivatePersonContractsStep2" },
      { component: "AddPrivatePersonContractsStep3" },
    ];

    const state = reactive<NewPrivatePersonContractStepsData>({
      contractNumber: "",
      validationCode: "",
      currentUserPhone: "",
      emailReceipt: true
    });

    if (authenticationModule.userProfile != null) {
      state.currentUserPhone = authenticationModule.userProfile
        .phone_number as string;
    }

    const currentStepRef = ref();

    const emailSetted = () => authenticationModule.profile && authenticationModule.profile.email && authenticationModule.profile.email_verified

    const { push } = useRouter()
    const setEmail = () => push({ name: AppRouteNames.UserProfile, query: { focus: 'email' } })

    return {
      setEmail,
      emailSetted,
      addPrivateContractConfirmLoading: ref(false),
      currentStepRef,
      currentStep: ref<number>(0),
      state,
      steps,
      processingError: ref(""),
    };
  },
  methods: {
    onOk() {
      this.processingError = "";
      this.currentStepRef.validate().then(async (validationResult: boolean) => {
        Logger.logInfo("Validation result", validationResult);

        if (validationResult === true) {
          this.addPrivateContractConfirmLoading = true;

          var registrationManager = await ProxyFactory.createRegistrationManagerProxy();
          registrationManager
            .addPrivatePersonContractOrder(
              this.state.contractNumber,
              this.state.validationCode,
              this.state.emailReceipt
            )
            .then(
              async (value) => {
                await managementObjectsModule.loadPrivatePersonContracts();
                this.addPrivateContractConfirmLoading = false;
                this.next();
              },
              (reason) => {
                this.addPrivateContractConfirmLoading = false;
                this.processingError = reason.message;
              }
            );
        }
      });
    },

    onClose(e: MouseEvent) {
      this.$emit("dialog-closed", DialogResult.Ok);
      this.isVisible = false;
    },

    next(): void {
      if (!this.currentStepRef.validate()) {
        return;
      }

      this.currentStep++;
    },
  },

  computed: {
    isVisible: {
      get() {
        return managementObjectsModule.newPrivatePersonContractDialogVisible;
      },
      set(newValue: boolean) {
        Logger.logInfo("Dialog closed");
        this.currentStep = 0;
        this.processingError = this.state.contractNumber = this.state.validationCode = this.state.currentUserPhone = '';
        managementObjectsModule.setNewPrivatePersonContractDialogVisible(newValue);
      },
    },
  },
});
