
import { defineComponent, PropType } from "vue";
import { NewPrivatePersonContractStepsData } from "@/common/managementObjects";
import { Logger } from "@/common/logger";

export default defineComponent({
  name: "AddPrivatePersonContractsStep3",
  props: {
    modelValue: {
      type: Object as PropType<NewPrivatePersonContractStepsData>,
      required: true,
    },
  },

  methods: {
    validate(): boolean {
      Logger.logInfo("Validate step 3 CALLED!");
      return true;
    },
  },
});
