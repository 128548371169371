import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "step-title" }
const _hoisted_2 = { class: "step-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, " Лицевой счёт " + _toDisplayString(_ctx.modelValue.contractNumber) + " успешно добавлен. ", 1),
    _createElementVNode("div", _hoisted_2, " Заполнение данных займёт несколько минут. Вы получите SMS на номер " + _toDisplayString(_ctx.modelValue.currentUserPhone) + ", когда всё будет готово. ", 1)
  ], 64))
}