import { store } from "../index"
import { Logger } from "@/common/logger";
import ProxyFactory from "@/apiProxies/proxyFactory";
import { FilesConfiguration } from '@/common/contractsFilesConfiguration';
import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import lodash from "lodash";

const moduleName = 'configurationModule'

@Module({ store, name: moduleName, namespaced: true, dynamic: true })
export class ConfigurationModule extends VuexModule {

    private defaultFilesConfiguraion: FilesConfiguration = {
        fileMaximumCount: 0,
        fileMaximumBytes: 0,
        fileExtensions: [],
        fileMinimumBytes: 0,
    }

    private _appealFilesConfiguration = { ...this.defaultFilesConfiguraion }
    private _contractsFilesConfiguraion = { ...this.defaultFilesConfiguraion }
    private _techConnectionFilesConfiguraion = { ...this.defaultFilesConfiguraion }

    get appealFilesConfiguration(): FilesConfiguration {
        return this._appealFilesConfiguration
    }

    get contractsFilesConfiguration(): FilesConfiguration {
        return this._contractsFilesConfiguraion
    }

    get techConnectionFilesConfiguration(): FilesConfiguration {
        return this._techConnectionFilesConfiguraion
    }

    @Mutation
    setAppealFilesConfiguration(value: FilesConfiguration): void {
        this._appealFilesConfiguration = value
    }

    @Mutation
    setContractsFilesConfiguration(value: FilesConfiguration): void {
        this._contractsFilesConfiguraion = value
    }

    @Mutation
    private setTechConnectionFilesConfiguration(value: FilesConfiguration): void {
        this._techConnectionFilesConfiguraion = value
    }

    @Action({ rawError: true })
    async updateContractsFilesConfiguration(): Promise<void> {
        if (lodash.isEqual(this.defaultFilesConfiguraion, this.contractsFilesConfiguration)) {
            try {
                const proxy = await ProxyFactory.createConfigurationPresentationProxy()

                const configuration = await proxy.getContractsFilesConfiguration()

                this.setContractsFilesConfiguration(configuration)
            }
            catch (exc) {
                Logger.logError("updateContractsFilesConfiguration FAILED!", exc)
                throw exc;
            }
        }
    }

    @Action({ rawError: true })
    async updateTechConnecitonFilesConfiguration(): Promise<void> {
        if (lodash.isEqual(this.defaultFilesConfiguraion, this.techConnectionFilesConfiguration)) {
            const proxy = await ProxyFactory.createConfigurationPresentationProxy()

            const configuration = await proxy.getTechConnectionFilesConfiguration()

            this.setTechConnectionFilesConfiguration(configuration)
        }
    }

    @Action({ rawError: true })
    async updateAppealsFilesConfiguration(): Promise<void> {
        if (lodash.isEqual(this.defaultFilesConfiguraion, this.appealFilesConfiguration)) {
            const proxy = await ProxyFactory.createConfigurationPresentationProxy()

            const configuration = await proxy.getAppealsFilesConfiguration()

            this.setAppealFilesConfiguration(configuration)
        }
    }
}

export const configurationModule = getModule(ConfigurationModule);