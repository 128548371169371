
import { Logger } from "@/common/logger";
import { defineComponent, PropType } from "vue";
import { NewPrivatePersonContractStepsData } from "@/common/managementObjects";
import { managementObjectsModule } from "@/store/modules/managementObjects";

export default defineComponent({
  name: "AddPrivatePersonContractsStep1",
  props: {
    modelValue: {
      type: Object as PropType<NewPrivatePersonContractStepsData>,
      required: true,
    },
  },
  computed: {
    initialData() {
      const data = managementObjectsModule.privateContractInitialData
      return data ? {
        startDateCheckedPaymentDocument: this.getFormattedDate(new Date(data.startDateCheckedPaymentDocument)),
        endDateCheckedPaymentDocument: this.getFormattedDate(new Date(data.endDateCheckedPaymentDocument))
      } : undefined
    }
  },
  methods: {
    validate(): boolean {
      Logger.logInfo("Validate step 1 CALLED!");
      return true;
    },
    getFormattedDate(date: Date): string {
      return date.toLocaleDateString("ru", {
        year: "numeric",
        month: "long",
      })
    }
  },
})
